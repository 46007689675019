import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage: React.FC = () => (
  <Layout>
    <SEO title="notfound." />
    <h1 data-testid="notfound-header">
      <span data-testid="notfound-accent" className="untilnow__typography__accent">
        not
      </span>
      found.
    </h1>
  </Layout>
);

export default NotFoundPage;
